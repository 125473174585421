@media (min-width: 0px) {
    .bcbe-player {
        /*padding-top: 56px;*/
    }
}

@media (min-width: 600px) {
    .bcbe-player {
        /*padding-top: 64px;*/
    }
}

.vimeo-player-wrap {
    padding:56.25% 0 0 0;position:relative;
}

.vimeo-player-frame {
    position:absolute;top:0;left:0;width:100%;height:100%;
}

.rhap_stacked .rhap_controls-section {
    margin-top: 0 !important;
}
