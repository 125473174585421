.chat-container { 

    max-width: 600px; 

    margin: 50px auto; 

    background-color: #fff; 

    border-radius: 8px; 

    overflow: hidden; 

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 

} 



.message-container { 

    display: flex; 

    flex-direction: column; 

} 



.message { 

    padding: 10px; 

    margin: 10px; 

    border-radius: 5px; 

    max-width: 70%; 

    word-wrap: break-word; 

    display: flex; 

    align-items: center; 

} 



.sender-message { 

    background-color: #e0e0e0; 

    color: #000; 

    align-self: flex-start; 

} 



.receiver-message { 

    background-color: #4CAF50; 

    color: #fff; 

    align-self: flex-end; 

} 



.avatar { 

    width: 30px; 

    height: 30px; 

    border-radius: 50%; 

    margin-right: 10px; 

} 



.message input { 

    width: calc(100% - 20px); 

    padding: 8px; 

    margin: 10px; 

    border: 1px solid #ddd; 

    border-radius: 5px; 

} 



.message button { 

    padding: 8px; 

    margin: 10px; 

    background-color: #4CAF50; 

    color: #fff; 

    border: none; 

    border-radius: 5px; 

    cursor: pointer; 

} 